









































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import Pagination from '@/components/base/Pagination.vue'
import { mapActions, mapState } from 'vuex'
import { UserGroup } from '@/api/interfaces/userGroup'
import router from '@/router'
import { PaginationOptions, parseQuery, usePagination } from '@/composables/pagination'
import { QueryParams } from '@/store/api-plateform-utils'
import { userCanAccess, userHasGroup } from '@/composables/UserGrant'
import { groupSuperAdmin } from '@/config-constantes'

export default defineComponent({
  name: 'UserGroupList',
  components: {
    TopBar,
    Pagination,
  },
  setup (props, ctx) {
    const canAddUserGroup = () => userCanAccess('UserGroup Create')
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.userGroupList.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      }: PaginationOptions = usePagination(
      totalItems,
      parseQuery(query),
    ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      canAddUserGroup,
    }
  },
  data () {
    return {
      title: this.$t('user_group.title_header'),
      headers: [
        {
          text: this.$t('user_group.list.headers.name'),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t('user_group.list.headers.actions'),
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('userGroupList', {
      list: 'list',
      loading: 'loading',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  watch: {
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  created () {
    this.load(this.baseRequest)
  },
  methods: {
    ...mapActions('userGroupList', {
      load: 'load',
    }),
    gotToUserGroupForm (item: UserGroup) {
      if (item.id) {
        this.$router.push({
          name: 'UserGroup Edit',
          params: { idUserGroup: item.id.toString() },
        })
      }
    },
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )

      router.replace({ name: 'UserGroup List', query: query })
    },
    showEditButton (item: UserGroup): boolean {
      return item.name !== groupSuperAdmin || userHasGroup(groupSuperAdmin)
    },
  },
})
